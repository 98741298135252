import gsap from '../../node_modules/gsap/dist/gsap.min';
import bootstrap from '../../node_modules/bootstrap/dist/js/bootstrap.min';
import barba from '../../node_modules/@barba/core/';
import barbaRouter from '../../node_modules/@barba/router';
import Splitting from '../../node_modules/splitting/dist/splitting.min';
import Swiper from '../../node_modules/swiper/swiper-bundle.min';
import alertify from '../../node_modules/alertifyjs/build/alertify.min';
import '../../node_modules/jquery-validation/dist/jquery.validate.min.js';
import "../../node_modules/jquery-match-height/dist/jquery.matchHeight-min";
import "../../node_modules/jquery-countto/jquery.countTo";
import "../../node_modules/inputmask/dist/jquery.inputmask.min";
import "../../node_modules/jquery-idletimer/dist/idle-timer.min";
import "../../node_modules/countdowntimer/dist/js/jQuery.countdownTimer.min";
import "./plugins/flowtype";
import bodymovin from "./plugins/lottie.min";
import "./plugins/scrollfire";
import Powerange from "./plugins/powerange.min";




(function( root, $, undefined ) { 
'use strict';
$(function(){
var PathUrl = $("body").attr("data-path"),
SiteUrl = $("body").attr("data-url"),
n = $(".btn128").length;
var WindowHeight = function(){
$('.get_height').css({'min-height':  window.innerHeight+ 'px'});
$('.get_height_total').css({'height':  window.innerHeight+ 'px'});
$('.get_mid_height').css({'min-height':  window.innerHeight/2+ 'px'});
$('.get_width').css({'min-width':  window.innerWidth+ 'px'});
$(".bh").css({'height':  window.innerHeight/n+ 'px'});
}

$(document).on("scroll",function () {
var $nav = $(".header");
$nav.toggleClass('scrolled', $(this).scrollTop() > $nav.height());
});

alertify.dialog('alert').set({maximizable: false, resizable:false, movable:false, title:"GROEX",transition:"zoom",closable:false});
alertify.dialog('confirm').set({maximizable: false, resizable:false, movable:false, title:"GROEX",transition:"zoom",closable:false});
alertify.set('notifier','position', 'bottom-center');
alertify.set('notifier','delay',5);

var menu = gsap.timeline({paused:true});
menu.to(".main-menu", {left: "0%", opacity:1, autoAlpha:1, duration: 1, ease: "expo.inOut"},0)
.to(".btn128", {top: "0px", opacity:1, autoAlpha:1, duration: 1, ease: "elastic.out(1,1)", stagger: 0.2},1)
.to(".main-menu .block-menu", {opacity:0, autoAlpha:0, duration: 0.5},2.2);

$('.hamburguer').on("click",function(){
$(this).toggleClass('open');
menu.timeScale(1).play(0);
});

$(".close-menu").on("click",function(e){
e.preventDefault();
menu.timeScale(3).reverse(0);
$('.hamburguer').toggleClass('open');
});

$("#chat-useremail").inputmask({
mask: "*{1,20}[.*{1,20}][.*{1,20}][.*{1,20}]@*{1,20}[.*{2,6}][.*{1,2}]",
greedy: false,
onBeforePaste: function (pastedValue, opts) {
pastedValue = pastedValue.toLowerCase();
return pastedValue.replace("mailto:", "");
},
definitions: {
'*': {
validator: "[0-9A-Za-z!#$%&'*+/=?^_`{|}~\-]",
casing: "lower"
}
}
});

var chat = gsap.timeline({paused:true});
chat.to(".chat", {marginBottom: "0", opacity:1, autoAlpha:1, display:'flex', duration: 0.8, ease: "expo.inOut",onReverseComplete: function() {
    $(".chat").hide();
},});

$(".chat-btn").on("click",function(e){
e.preventDefault();
$('.messages').scrollTop($('.messages')[0].scrollHeight);
$(".inputphone").inputmask({ mask: "+56\\9\\ 9999-9999", greedy: false});
$(".inputemail").inputmask({
mask: "*{1,20}[.*{1,20}][.*{1,20}][.*{1,20}]@*{1,20}[.*{2,6}][.*{1,2}]",
greedy: false,
onBeforePaste: function (pastedValue, opts) {
pastedValue = pastedValue.toLowerCase();
return pastedValue.replace("mailto:", "");
},
definitions: {
'*': {
validator: "[0-9A-Za-z!#$%&'*+/=?^_`{|}~\-]",
casing: "lower"
}
}
});
$(this).toggleClass("active");
if($(this).hasClass("active")){
    chat.play();
}else{
    chat.reverse(0);
}
});

$("#chat").idleTimer({
    timeout:600000, 
    idle:true
});

var idtime;

$('#chat').on("idle.idleTimer", function (event, elem, obj) {
    event.stopPropagation();
    var action = "InactiveChat",
    mainchat = $("#chat").html(),
    DataUser = {action:action, mainchat:mainchat};
    $.ajax({
    data:  DataUser,
    url:   SiteUrl+"/json",
    type:  'post',
    cache: false,
    success:function(response) {	
    if(response.message == "success"){
        gsap.to(".chatbox .chat .blockchat", {opacity:1, autoAlpha:1, duration: 0.5});
        $(".messages").append('<div class="message typingload"><div class="typing typing-1"></div><div class="typing typing-2"></div><div class="typing typing-3"></div></div>');
        gsap.to(".typingload:last-child", {top:0, opacity:1, autoAlpha:1, duration: 0.8, ease:"elastic.out(1,1)"});
        $('.messages').scrollTop($('.messages')[0].scrollHeight);
        setTimeout(function() { 
        $(".typingload").remove();
        $(".messages").append(response.data);
        gsap.to(".bot:last-child", {top:0, opacity:1, autoAlpha:1, duration: 0.8, ease:"elastic.out(1,1)"});
        $('.messages').scrollTop($('.messages')[0].scrollHeight);
            setTimeout(function() { 
                idtime = "#time-"+response.time+"";
                $(idtime).countdowntimer({
                    seconds : 30,
                    size : "lg",
                    padZeroes : false,
                    displayFormat : "S",
                    timeUp : timeIsUp,
                });
                gsap.to(".chatbox .chat .blockchat", {opacity:0, autoAlpha:0, duration: 0.5});
            }, 100);
        }, 2000);
        
    }else{
        
    }
    }
    });

});

$('#chat').on("active.idleTimer", function (event) {
    event.stopPropagation();
    if(idtime){
        $(idtime).countdowntimer("pause","pause");
    }
});

function timeIsUp() {
    var action = "EndChat",
    mainchat = $("#chat").html(),
    DataUser = {action:action, mainchat:mainchat};
    $.ajax({
    data:  DataUser,
    url:   SiteUrl+"/json",
    type:  'post',
    cache: false,
    success:function(response) {	
    if(response.message == "success"){
        $(idtime).countdowntimer("destroy");
        gsap.to(".chatbox .chat .blockchat", {opacity:1, autoAlpha:1, duration: 0.5});
        $(".messages").append('<div class="message typingload"><div class="typing typing-1"></div><div class="typing typing-2"></div><div class="typing typing-3"></div></div>');
        gsap.to(".typingload:last-child", {top:0, opacity:1, autoAlpha:1, duration: 0.8, ease:"elastic.out(1,1)"});
        $('.messages').scrollTop($('.messages')[0].scrollHeight);
        setTimeout(function() { 
            $(".typingload").remove();
            $(".messages").append(response.data);
            gsap.to(".bot:last-child", {top:0, opacity:1, autoAlpha:1, duration: 0.8, ease:"elastic.out(1,1)"});
            $('.messages').scrollTop($('.messages')[0].scrollHeight);
            setTimeout(function() { 
                $(".chat-btn").toggleClass("active");
                chat.reverse(0);
                setTimeout(function() { 
                    $(".chat-userdata").removeClass("hideform").addClass("showform");
                    $(".chat-loader").css("display","none");
                    gsap.to(".chat-userdata", {opacity:1, autoAlpha:1, duration: 0.8, ease:"elastic.out(1,1)"});
                    gsap.to(".chatbox .chat .blockchat", {opacity:0, autoAlpha:0, duration: 0.5});
                }, 500); 
            }, 2000);   
        }, 2000);   
    }
    }
    });
}	

class Particle{

constructor(svg, coordinates, friction){
this.svg = svg
this.steps = ($(window).height())/2
this.item = null
this.friction = friction
this.coordinates = coordinates
this.position = this.coordinates.y
this.dimensions = this.render()
this.rotation = Math.random() > 0.5 ? "-" : "+"
this.scale = 0.5 + Math.random()
this.siner = 200 * Math.random()
}

destroy(){
this.item.remove()
}

move(){
this.position = this.position - this.friction
let top = this.position;
let left = this.coordinates.x + Math.sin(this.position*Math.PI/this.steps) * this.siner;
this.item.css({
transform: "translateX("+left+"px) translateY("+top+"px) scale(" + this.scale + ") rotate("+(this.rotation)+(this.position + this.dimensions.height)+"deg)"
})

if(this.position < -(this.dimensions.height)){
this.destroy()
return false
}else{
return true
}
}

render(){
this.item = $(this.svg, {
css: {
transform: "translateX("+this.coordinates.x+"px) translateY("+this.coordinates.y+"px)"
}
})
$(".graphs").append(this.item)
return {
width: this.item.width(),
height: this.item.height()
}
}
}

const rhombus = '<svg aria-hidden="true" role="presentation" viewBox="0 0 13 14"><path class="rhombus" d="M5.9,1.2L0.7,6.5C0.5,6.7,0.5,7,0.7,7.2l5.2,5.4c0.2,0.2,0.5,0.2,0.7,0l5.2-5.4 C12,7,12,6.7,11.8,6.5L6.6,1.2C6.4,0.9,6.1,0.9,5.9,1.2L5.9,1.2z M3.4,6.5L6,3.9c0.2-0.2,0.5-0.2,0.7,0l2.6,2.6 c0.2,0.2,0.2,0.5,0,0.7L6.6,9.9c-0.2,0.2-0.5,0.2-0.7,0L3.4,7.3C3.2,7.1,3.2,6.8,3.4,6.5L3.4,6.5z" /></svg>'

const pentahedron = '<svg aria-hidden="true" role="presentation" viewBox="0 0 561.8 559.4"><path class="pentahedron" d="M383.4,559.4h-204l-2.6-0.2c-51.3-4.4-94-37-108.8-83l-0.2-0.6L6,276.7l-0.2-0.5c-14.5-50,3.1-102.7,43.7-131.4 L212.1,23C252.4-7.9,310.7-7.9,351,23l163.5,122.5l0.4,0.3c39,30.3,56,82.6,42.2,130.3l-0.3,1.1l-61.5,198 C480.4,525.6,435.5,559.4,383.4,559.4z M185.5,439.4h195.2l61.1-196.8c0-0.5-0.3-1.6-0.7-2.1L281.5,120.9L120.9,241.2 c0,0.3,0.1,0.7,0.2,1.2l60.8,195.8C182.5,438.5,183.7,439.1,185.5,439.4z M441,240.3L441,240.3L441,240.3z"/></svg>'
const x = '<svg viewBox="0 0 12 12"> <path class="x" d="M10.3,4.3H7.7V1.7C7.7,0.8,7,0,6,0S4.3,0.8,4.3,1.7v2.5H1.7C0.8,4.3,0,5,0,6s0.8,1.7,1.7,1.7h2.5v2.5 C4.3,11.2,5,12,6,12s1.7-0.8,1.7-1.7V7.7h2.5C11.2,7.7,12,7,12,6S11.2,4.3,10.3,4.3z"/></svg>'

const circle = '<svg aria-hidden="true" role="presentation" x="0px" y="0px" viewBox="0 0 13 12"> <path class="circle" d="M6.5,0.1C3.4,0.1,0.8,2.8,0.8,6s2.6,5.9,5.7,5.9s5.7-2.7,5.7-5.9S9.7,0.1,6.5,0.1L6.5,0.1z M6.5,8.8 C5,8.8,3.8,7.6,3.8,6S5,3.2,6.5,3.2S9.2,4.4,9.2,6S8,8.8,6.5,8.8L6.5,8.8z"/> </svg>'

const point = '<svg aria-hidden="true" role="presentation" viewBox="0 0 12 12"> <path class="point" d="M6,7.5L6,7.5C5.1,7.5,4.5,6.9,4.5,6v0c0-0.9,0.7-1.5,1.5-1.5h0c0.9,0,1.5,0.7,1.5,1.5v0C7.5,6.9,6.9,7.5,6,7.5z "/> </svg>'

function randomInt(min,max){
return Math.floor(Math.random()*(max-min+1)+min);
}

const data = [point, rhombus, pentahedron, circle, x]

let isPaused = false;
window.onblur = function() {
isPaused = true;
}.bind(this)
window.onfocus = function() {
isPaused = false;
}.bind(this)

let particles = []

setInterval(function(){
if (!isPaused){
particles.push(
new Particle(data[randomInt(0,data.length-1)], {
"x": (Math.random() * $(window).width()),
"y": $(window).height()
}, (1 + Math.random() * 3) )
)
}
}, 200)

function update(){
particles = particles.filter(function(p){
return p.move()
})
requestAnimationFrame(update.bind(this))
}
update()


$(".go-to").on("click",function(){

let href = $(location).attr('href'),
parts_url = href.split("/"),
last_part_url = parts_url[parts_url.length-2];

let link = $(this).attr('href'),
parts_link = link.split("/"),
last_part_link = parts_link[parts_link.length-2];

if(last_part_link == last_part_url ){
menu.timeScale(3).reverse(0);
$('.hamburguer').toggleClass('open');
return false; 
}else{
setTimeout(function() { 
menu.timeScale(3).reverse(0);
$('.hamburguer').toggleClass('open');
}, 500);
}
});

$('.btn128').flowtype({
fontRatio : 15,
minFont : 55,
});

$('.title-page').flowtype({
fontRatio : 15,
minFont : 55,
});

$('.btn128 h3').flowtype({
fontRatio : 15,
minFont : 55,
});

var transition = gsap.timeline({paused:true});
transition.to("#wrapper", {opacity:0, duration: 0.8, ease: "expo.inOut"},0)
.to(".transition", {y: "0%", duration: 0.8, ease: "expo.inOut"},0)
.to(".transition", {y: "-100%", duration: 0.8, ease: "expo.inOut"},1)
.to("#wrapper", {opacity:1, duration: 0.8, ease: "expo.inOut"},1.5);

$.validator.addMethod(
    "regex",
    function(value, element, regexp) 
    {
        if (regexp.constructor != RegExp)
            regexp = new RegExp(regexp);
        else if (regexp.global)
            regexp.lastIndex = 0;
        return this.optional(element) || regexp.test(value);
    },
    "Ingresa un email valido."
);

$("#EnterChat").validate({ 
onfocusout: false,
rules: {
    chatusername: { 
        required: true,
        minlength: 4,
        maxlength: 20
    },  
    chatuseremail: {
        required: true,
        email: true,
        regex: /^[A-Za-z0-9_]+\@[A-Za-z0-9_]+\.[A-Za-z0-9_]+/, 
    }
},
messages: {
    chatusername: {
        required: "Ingres tu nombre",
        minlength: "Mínimo 4 caracteres",
        maxlength: "Maximo 20 caracteres"
    },
    chatuseremail: {
        required: "Ingresa tu email",
        email: "Ingresa un email valido"
    }
},
submitHandler: function(form){
    $(".chat-loader").fadeIn("fast").css("display","flex");
    var action = "EnterChat",
    chatname = $("#chat-username").val(),	
    chatemail = $("#chat-useremail").val(),	
    DataUser = {action:action,chatname:chatname,chatemail:chatemail};
    $.ajax({
    data:  DataUser,
    url:   SiteUrl+"/json",
    type:  'post',
    cache: false,
    success:function(response) {	
    if(response.message == "success"){
        var dataid = $(this).attr("data-id"); 
        gsap.to($("#"+dataid), {opacity:0.5, duration: 0.8, ease:"elastic.out(1,1)"});
        $("#"+dataid).addClass("message-disabled");
        $("#"+dataid).find(".btnpreg").addClass("message-disabled");
        $(".messages").empty();
        gsap.to(".chat-userdata", {opacity:0, autoAlpha:0, duration: 0.8, ease:"elastic.out(1,1)"});
        $(".messages").append('<div class="message typingload"><div class="typing typing-1"></div><div class="typing typing-2"></div><div class="typing typing-3"></div></div>');
        gsap.to(".typingload:last-child", {top:0, opacity:1, autoAlpha:1, duration: 0.8, ease:"elastic.out(1,1)"});
        $('.messages').scrollTop($('.messages')[0].scrollHeight);
        setTimeout(function() { 
            $(".typingload").remove();
            $(".messages").append(response.data);
            gsap.to(".bot:last-child", {top:0, opacity:1, autoAlpha:1, duration: 0.8, ease:"elastic.out(1,1)"});
            setTimeout(function() { 
                var update = "UpdateChat",
                mainchat = $("#chat").html(),
                DataUser = {action:update,mainchat:mainchat};
                $.ajax({
                data:  DataUser,
                url:   SiteUrl+"/json",
                type:  'post',
                cache: false,
                success:function(response) {
                }
                });
                $("#chat-username").val("");
                $("#chat-useremail").val("");
            }, 50);
            $('.messages').scrollTop($('.messages')[0].scrollHeight);
        }, 1000);
    }else{
        
    }
    }
    });
},
invalidHandler: function(event, validator) {
    var errors = validator.numberOfInvalids();
    if (errors) { 
        var message = errors == 1 ? ' Error: Te perdiste 1 campo. Ha sido destacado' : ' Error: Te perdiste '+ errors +' campos. Se han destacado';
        $("div#formError span#Mensaje").html(message);
        $("div#formError").removeClass("hidden");
    }
},
highlight: function(element, errorClass) {

},
errorElement: "div",
errorClass: "alert alert-danger",
});

$("body").on("click",".BackMenu",function(){
var dataid = $(this).attr("data-id"); 
gsap.to($("#"+dataid), {opacity:0.5, duration: 0.8, ease:"elastic.out(1,1)"});
$("#"+dataid).addClass("message-disabled");
var action = "BackChat",
data_id = dataid,
DataUser = {action:action, data_id:data_id};
$.ajax({
data:  DataUser,
url:   SiteUrl+"/json",
type:  'post',
cache: false,
success:function(response) {	
if(response.message == "success"){
    $(".messages").append('<div class="message typingload"><div class="typing typing-1"></div><div class="typing typing-2"></div><div class="typing typing-3"></div></div>');
    gsap.to(".typingload:last-child", {top:0, opacity:1, autoAlpha:1, duration: 0.8, ease:"elastic.out(1,1)"});
    $('.messages').scrollTop($('.messages')[0].scrollHeight);
    setTimeout(function() { 
        $(".typingload").remove();
        $(".messages").append(response.data);
        gsap.to(".bot:last-child", {top:0, opacity:1, autoAlpha:1, duration: 0.8, ease:"elastic.out(1,1)"});
        setTimeout(function() { 
            var update = "UpdateChat",
            mainchat = $("#chat").html(),
            DataUser = {action:update,mainchat:mainchat};
            $.ajax({
            data:  DataUser,
            url:   SiteUrl+"/json",
            type:  'post',
            cache: false,
            success:function(response) {
            }
            });
            $('.messages').scrollTop($('.messages')[0].scrollHeight);
        }, 50);
    }, 1000);
}else{
    
}
}
});
});


$("body").on("click",".EndChat",function(){
var dataid = $(this).attr("data-id"); 
gsap.to($("#"+dataid), {opacity:0.5, duration: 0.8, ease:"elastic.out(1,1)"});
$("#"+dataid).find("button").prop('disabled', true);
$("#"+dataid).addClass("message-disabled");
var action = "EndChat",
mainchat = $("#chat").html(),
DataUser = {action:action, mainchat:mainchat};
$.ajax({
data:  DataUser,
url:   SiteUrl+"/json",
type:  'post',
cache: false,
success:function(response) {	
if(response.message == "success"){
    $(idtime).countdowntimer("destroy");
    gsap.to(".chatbox .chat .blockchat", {opacity:1, autoAlpha:1, duration: 0.5});
    $(".messages").append('<div class="message typingload"><div class="typing typing-1"></div><div class="typing typing-2"></div><div class="typing typing-3"></div></div>');
    gsap.to(".typingload:last-child", {top:0, opacity:1, autoAlpha:1, duration: 0.8, ease:"elastic.out(1,1)"});
    $('.messages').scrollTop($('.messages')[0].scrollHeight);
    setTimeout(function() { 
        $(".typingload").remove();
        $(".messages").append(response.data);
        gsap.to(".bot:last-child", {top:0, opacity:1, autoAlpha:1, duration: 0.8, ease:"elastic.out(1,1)"});
        $('.messages').scrollTop($('.messages')[0].scrollHeight);
        setTimeout(function() { 
            $(".chat-btn").toggleClass("active");
            chat.reverse(0);
            setTimeout(function() { 
                $(".chat-userdata").removeClass("hideform").addClass("showform");
                $(".chat-loader").css("display","none");
                gsap.to(".chat-userdata", {opacity:1, autoAlpha:1, duration: 0.8, ease:"elastic.out(1,1)"});
                gsap.to(".chatbox .chat .blockchat", {opacity:0, autoAlpha:0, duration: 0.5});
            }, 500); 
        }, 2000);   
    }, 2000);   
}
}
});
});


$("body").on("click",".btnpreg",function(){
var dt = new Date(),
time = dt.getHours() + ":" + dt.getMinutes(),
dataid = $(this).attr("data-id"); 
gsap.to($("#"+dataid), {opacity:0.5, duration: 0.8, ease:"elastic.out(1,1)"});
$("#"+dataid).find("button").prop('disabled', true);
$("#"+dataid).addClass("message-disabled");
gsap.to(".chatbox .chat .blockchat", {opacity:1, autoAlpha:1, duration: 0.5});
$(".messages").append('<div class="message user"><p>'+$(this).text()+'</p><time datetime="'+time+'">'+time+'</time></div>');
gsap.to(".user:last-child", {top:0, opacity:1, autoAlpha:1, duration: 0.8, ease:"elastic.out(1,1)"});
$('.messages').scrollTop($('.messages')[0].scrollHeight);
var action = "GetRespuesta",
pregindex = $(this).attr("data-preg"),
pregtype = $(this).attr("data-tipo"),
DataUser = {action:action,pregindex:pregindex, pregtype:pregtype};
$.ajax({
data:  DataUser,
url:   SiteUrl+"/json",
type:  'post',
cache: false,
success:function(response) {	
if(response.message == "success"){
    $(".messages").append('<div class="message typingload"><div class="typing typing-1"></div><div class="typing typing-2"></div><div class="typing typing-3"></div></div>');
    gsap.to(".typingload:last-child", {top:0, opacity:1, autoAlpha:1, duration: 0.8, ease:"elastic.out(1,1)"});
    $('.messages').scrollTop($('.messages')[0].scrollHeight);
    setTimeout(function() { 
        $(".typingload").remove();
        gsap.to(".chatbox .chat .blockchat", {opacity:0, autoAlpha:0, duration: 0.5});
        $(".messages").append(response.data);
        gsap.to(".bot:last-child", {top:0, opacity:1, autoAlpha:1, duration: 0.8, ease:"elastic.out(1,1)"});
        $('.messages').scrollTop($('.messages')[0].scrollHeight);
        setTimeout(function() { 
            var update = "UpdateChat",
            mainchat = $("#chat").html(),
            DataUser = {action:update,mainchat:mainchat};
            $.ajax({
            data:  DataUser,
            url:   SiteUrl+"/json",
            type:  'post',
            cache: false,
            success:function(response) {	
            if(response.message == "updatechat"){
            }
            }
            });
            if(response.type == "telefono"){
                $(".inputphone").inputmask({ mask: "+56\\9\\ 9999-9999", greedy: false});
            }else if(response.type == "email"){
                $(".inputemail").inputmask({
                    mask: "*{1,20}[.*{1,20}][.*{1,20}][.*{1,20}]@*{1,20}[.*{2,6}][.*{1,2}]",
                    greedy: false,
                    onBeforePaste: function (pastedValue, opts) {
                    pastedValue = pastedValue.toLowerCase();
                    return pastedValue.replace("mailto:", "");
                    },
                    definitions: {
                    '*': {
                    validator: "[0-9A-Za-z!#$%&'*+/=?^_`{|}~\-]",
                    casing: "lower"
                    }
                    }
                });
            }
        }, 300);
    }, 2000);
}else{
    
}
}
});
});


$("body").on("click",".btnpregrep",function(){
var dt = new Date();
var time = dt.getHours() + ":" + dt.getMinutes();
gsap.to(".chatbox .chat .blockchat", {opacity:1, autoAlpha:1, duration: 0.5});
$(".messages").append('<div class="message user"><p>'+$(this).text()+'</p><time datetime="'+time+'">'+time+'</time></div>');
gsap.to(".user:last-child", {top:0, opacity:1, autoAlpha:1, duration: 0.8, ease:"elastic.out(1,1)"});
$('.messages').scrollTop($('.messages')[0].scrollHeight);
var action = "GetRespuestaRep",
datapreg = $(this).attr("data-preg"),
dataindex = $(this).attr("data-index"),
DataUser = {action:action,datapreg:datapreg, dataindex:dataindex};
$.ajax({
data:  DataUser,
url:   SiteUrl+"/json",
type:  'post',
cache: false,
success:function(response) {	
    if(response.message == "success"){
    $(".messages").append('<div class="message typingload"><div class="typing typing-1"></div><div class="typing typing-2"></div><div class="typing typing-3"></div></div>');
    gsap.to(".typingload:last-child", {top:0, opacity:1, autoAlpha:1, duration: 0.8, ease:"elastic.out(1,1)"});
    $('.messages').scrollTop($('.messages')[0].scrollHeight);
    setTimeout(function() { 
    $(".typingload").remove();
    gsap.to(".chatbox .chat .blockchat", {opacity:0, autoAlpha:0, duration: 0.5});
    $(".messages").append(response.data);
    gsap.to(".bot:last-child", {top:0, opacity:1, autoAlpha:1, duration: 0.8, ease:"elastic.out(1,1)"});
    $('.messages').scrollTop($('.messages')[0].scrollHeight);
        setTimeout(function() { 
            var update = "UpdateChat",
            mainchat = $("#chat").html(),
            DataUser = {action:update,mainchat:mainchat};
            $.ajax({
            data:  DataUser,
            url:   SiteUrl+"/json",
            type:  'post',
            cache: false,
            success:function(response) {	
            if(response.message == "updatechat"){
            }
            }
            });
            if(response.type == "telefono"){
                $(".inputphone").inputmask({ mask: "+56\\9\\ 9999-9999", greedy: false});
            }else if(response.type == "email"){
                $(".inputemail").inputmask({
                    mask: "*{1,20}[.*{1,20}][.*{1,20}][.*{1,20}]@*{1,20}[.*{2,6}][.*{1,2}]",
                    greedy: false,
                    onBeforePaste: function (pastedValue, opts) {
                    pastedValue = pastedValue.toLowerCase();
                    return pastedValue.replace("mailto:", "");
                    },
                    definitions: {
                    '*': {
                    validator: "[0-9A-Za-z!#$%&'*+/=?^_`{|}~\-]",
                    casing: "lower"
                    }
                    }
                });
            }
        }, 300);
    }, 2000);
    }else{

    }
}
});
});



$("body").on("click",".btndata",function(){

var datatype = $(this).attr("data-input"),
dataid = $(this).attr("data-id"),
lastItem = dataid.split("-").pop();

$("."+lastItem).remove();

if(datatype == "telefono"){

    if($("#"+dataid).val().length < 4) {  
        $('div[input-id="'+lastItem+'"]').append('<div class="alert alert-danger chat-input-error '+lastItem+'">Ingresa tu teléfono</div>');
        return false;  
    }
    if($("#"+dataid).val().replace(/[^0-9]/g,"").length < 11) { 
        $('div[input-id="'+lastItem+'"]').append('<div class="alert alert-danger chat-input-error '+lastItem+'">Ingresa un teléfono valido</div>');
        return false;   
    }

}else if(datatype == "email"){
    if($("#"+dataid).val().length < 1) {  
        $('div[input-id="'+lastItem+'"]').append('<div class="alert alert-danger chat-input-error '+lastItem+'">Ingresa tu email</div>'); 
        return false;  
    }  
    if($("#"+dataid).val().indexOf('@', 0) == -1 || $("#"+dataid).val().indexOf('.', 0) == -1) {  
        $('div[input-id="'+lastItem+'"]').append('<div class="alert alert-danger chat-input-error '+lastItem+'">El email ingresado contiene errores.</div>'); 
        return false;  
    }

}else if(datatype == "mensaje"){
    if($("#"+dataid).val().length < 10) {  
        $('div[input-id="'+lastItem+'"]').append('<div class="alert alert-danger chat-input-error '+lastItem+'">Ingresa tu comentario.</div>'); 
        return false;  
    } 
}

if(datatype == "mensaje"){
$("#"+dataid).html($("#"+dataid).val()); 
}else{
$("#"+dataid).attr("value", $("#"+dataid).val()); 
}

gsap.to($("#"+lastItem), {opacity:0.5, duration: 0.8, ease:"elastic.out(1,1)"});
$("#"+lastItem).find("button").prop('disabled', true);
$("#"+lastItem).addClass("message-disabled");

var action = "SaveData",
inputdata = $("#"+dataid).val(),	
typedata = datatype,
DataUser = {action:action,inputdata:inputdata,typedata:typedata};
$.ajax({
data:  DataUser,
url:   SiteUrl+"/json",
type:  'post',
cache: false,
success:function(response) {	
if(response.message == "success"){
    $(".messages").append('<div class="message typingload"><div class="typing typing-1"></div><div class="typing typing-2"></div><div class="typing typing-3"></div></div>');
    gsap.to(".typingload:last-child", {top:0, opacity:1, autoAlpha:1, duration: 0.8, ease:"elastic.out(1,1)"});
    $('.messages').scrollTop($('.messages')[0].scrollHeight);
    setTimeout(function() { 
    $(".typingload").remove();
    gsap.to(".chatbox .chat .blockchat", {opacity:0, autoAlpha:0, duration: 0.5});
    $(".messages").append(response.data);
    gsap.to(".bot:last-child", {top:0, opacity:1, autoAlpha:1, duration: 0.8, ease:"elastic.out(1,1)"});
    $('.messages').scrollTop($('.messages')[0].scrollHeight);
        setTimeout(function() { 
            var update = "UpdateChat",
            mainchat = $("#chat").html(),
            DataUser = {action:update,mainchat:mainchat};
            $.ajax({
            data:  DataUser,
            url:   SiteUrl+"/json",
            type:  'post',
            cache: false,
            success:function(response) {	
            if(response.message == "updatechat"){
            }
            }
            });
        }, 300);
    }, 2000);
    }else{

    }
}
});

});


const Home = function(){

WindowHeight();
window.scrollTo(0, 0);


gsap.to(".graphs", {opacity:1, duration: 0.3, ease:"elastic.out(1,1)"});
$(".hamburguer-wrapp").removeClass("black");

$("body").css({'padding-bottom':  '0px'});
$(".head-logo img").attr("src", PathUrl+"/dist/img/logo-white.svg");

$('.TitleBanner, .title-av').flowtype({
fontRatio : 14,
minFont : 45,
});

$('.SubtitleBanner').flowtype({
fontRatio : 55,
minFont : 20,
});

const title1 = document.querySelector('.TitleBanner');
const results1 = Splitting({ target: title1, by: 'chars' });

const title2 = document.querySelector('.title-av');
const results2 = Splitting({ target: title2, by: 'chars' });

gsap.set(".SubtitleBanner", {y: 100, opacity:0});
gsap.set(".TitleBanner .char", {y: 100,});
gsap.set(".header", {y: -100, opacity:0});
gsap.set(".start", {y: 200, opacity:0});

var tl_home = gsap.timeline({delay: 0.4});
tl_home.to(".TitleBanner", {opacity:1, duration: 0.3, onComplete:function(){
tl_home.to(".TitleBanner .char", {y: 0, duration: 0.8, ease: "elastic.out(1,1)", stagger: 0.02, onComplete:function(){
    gsap.to(".SubtitleBanner", {y: 0, opacity:1 ,duration: 0.8, ease: "elastic.out(1,1)"});
    gsap.to(".header", {y: 0, opacity:1 ,duration: 0.8, ease: "elastic.out(1,1)"});
    gsap.to(".start", {y: 0, opacity:1, autoAlpha:1, delay:0.5, duration: 1, ease: "elastic.out(1,1)"}); 
}});
}});
}

const somos = function(){

$("body").css({'padding-bottom':  $(".footer").outerHeight(true)+ 'px'});
WindowHeight();
window.scrollTo(0, 0); 

gsap.to(".graphs", {opacity:1, duration: 0.3, ease:"elastic.out(1,1)"});
$(".hamburguer-wrapp").addClass("black");

$('#counter').scrollfire({
offset: 0,
topOffset: 100,
onBottomIn: function( elm, distance_scrolled ) {
$('.number').countTo();
},
});

$(".head-logo img").attr("src", PathUrl+"/dist/img/logo-white-2.svg");

$('.title-somos').flowtype({
fontRatio : 11,
minFont : 54,
});

$('.info-somos').flowtype({
fontRatio : 50,
minFont : 23,
});

$('.somos-title').flowtype({
fontRatio : 6,
});

$('.titulo-clientes').flowtype({
fontRatio : 14,
minFont : 30,
});

$('.titulo-trabajos').flowtype({
fontRatio : 14,
minFont : 30,
});

$('.number').flowtype({
fontRatio : 3,
minFont : 16,
});

$('.title-proceso').flowtype({
fontRatio : 20,
minFont   : 40,
});

$('.item-proceso').matchHeight({
byRow: false,
property: 'min-height',
target: null,
remove: false
});

var swiper_proceso = new Swiper(".swiper-proceso", {
slidesPerView: 3,
simulateTouch:false,
pagination: {
el: ".swiper-pagination",
clickable: true,
},
breakpoints: {
320: {
slidesPerView: 1,
spaceBetween: 50,
},
480: {
slidesPerView: 1,
spaceBetween: 50,
},
768: {
slidesPerView: 2,
spaceBetween: 50,
},
1024: {
slidesPerView: 3,
spaceBetween: 50,
},
1140: {
slidesPerView: 3,
spaceBetween: 50,
},
},
});

const title_servicios = document.querySelector('.title-somos');
const results1 = Splitting({ target: title_servicios, by: 'chars' });

gsap.set(".info-somos", {y: 100, opacity:0});
gsap.set(".title-somos .char", {y: 200});
gsap.set(".header", {y: -100, opacity:0});

var tl_somos = gsap.timeline({delay: 0.4});
tl_somos.to(".title-somos", {opacity:1, duration: 0.3, onComplete:function(){
tl_somos.to(".title-somos .char", {y: 0, duration: 0.8, ease: "elastic.out(1,1)", stagger: 0.02, onComplete:function(){
    gsap.to(".info-somos", {y: 0, opacity:1 ,duration: 0.8, ease: "elastic.out(1,1)"});
    gsap.to(".header", {y: 0, opacity:1 ,duration: 0.8, ease: "elastic.out(1,1)"});
}});
}});

var logos = new Swiper(".logos-slider", {
loop:true,
speed:7000,
allowTouchMove: false,
disableOnInteraction: false,
autoplay: {
delay: 1,
},
breakpoints: {
320: {
slidesPerView: 2,
spaceBetween: 10,
},
480: {
slidesPerView: 2,
spaceBetween: 20,
},
768: {
slidesPerView: 3,
spaceBetween: 20,
},
1024: {
slidesPerView: 4,
spaceBetween: 20,
},
1140: {
slidesPerView: 5,
spaceBetween: 20,
},
},
});
}


const servicios = function(){ 

WindowHeight();
window.scrollTo(0, 0); 

gsap.to(".graphs", {opacity:1, duration: 0.3, ease:"elastic.out(1,1)"});
$(".hamburguer-wrapp").addClass("black");

$("body").css({'padding-bottom':  $(".footer").outerHeight(true)+ 'px'});
$(".head-logo img").attr("src", PathUrl+"/dist/img/logo-white-2.svg");

$('.title-servicio').flowtype({
fontRatio : 10,
minFont : 40,
});

$('.info-servicio').flowtype({
fontRatio : 50,
minFont : 23,
});

$('.title-swipe').flowtype({
fontRatio : 20,
minFont : 26,
});

$('.title-superv').flowtype({
fontRatio : 20,
minFont : 30,
});

$('.serv-title').flowtype({
fontRatio : 17,
});

var swiper = new Swiper(".serv-swipe",{
simulateTouch:false,
autoHeight:true,
effect: 'fade',
fadeEffect: {
    crossFade: true
}
});

$(".btn-serv").removeClass("active");
swiper.slideTo(1);
$(".btn-serv").first().addClass("active");
swiper.slideTo(0);

$(".btn-serv").on("click",function(e){
e.preventDefault();
$(".btn-serv").removeClass("active");
$(this).addClass("active");
let numslide = $(this).attr("data-slide");
swiper.slideTo(numslide);
});

$('.wrapp-serv').matchHeight({
byRow: false,
property: 'min-height',
target: null,
remove: false
});

const title_servicios = document.querySelector('.title-servicio');
const results1 = Splitting({ target: title_servicios, by: 'chars' });

gsap.set(".info-servicio", {y: 100, opacity:0});
gsap.set(".title-servicio .char", {y: 200,});
gsap.set(".header", {y: -100, opacity:0});

var tl_serv = gsap.timeline({delay: 0.4});
tl_serv.to(".title-servicio", {opacity:1, duration: 0.3, onComplete:function(){
tl_serv.to(".title-servicio .char", {y: 0, duration: 0.8, ease: "elastic.out(1,1)", stagger: 0.02, onComplete:function(){
    gsap.to(".info-servicio", {y: 0, opacity:1 ,duration: 0.8, ease: "elastic.out(1,1)"});
    gsap.to(".header", {y: 0, opacity:1 ,duration: 0.8, ease: "elastic.out(1,1)"});
}});
}});

$("#EmailDemo").inputmask({
mask: "*{1,20}[.*{1,20}][.*{1,20}][.*{1,20}]@*{1,20}[.*{2,6}][.*{1,2}]",
greedy: false,
onBeforePaste: function (pastedValue, opts) {
pastedValue = pastedValue.toLowerCase();
return pastedValue.replace("mailto:", "");
},
definitions: {
'*': {
validator: "[0-9A-Za-z!#$%&'*+/=?^_`{|}~\-]",
casing: "lower"
}
}
});

$("#get_demo").on("click",function(e){
e.preventDefault();
if($("#EmailDemo").val().length < 1) {  
alertify.notify('Ingresa tu email.', 'error', 2);   
return false;  
}  
if($("#EmailDemo").val().indexOf('@', 0) == -1 || $("#EmailDemo").val().indexOf('.', 0) == -1) {  
alertify.notify('El email ingresado contiene errores.', 'error', 2);  
return false;  
}
gsap.to(".supervision .preloader", {opacity:1, autoAlpha:1 ,duration: 0.8, ease: "elastic.out(1,1)"});
$('#get_demo').prop('disabled', true);
var action = "Demo",
EmailDemo = $("#EmailDemo").val(),		
DataUser = {action:action,EmailDemo:EmailDemo};
$.ajax({
data:  DataUser,
url:   SiteUrl+"/json",
type:  'post',
cache: false,
success:function(response) {	
if(response.message == "success"){
gsap.to(".supervision .preloader", {opacity:0, autoAlpha:0 ,duration: 1, ease: "elastic.out(1,1)"});
alertify.notify('Solicitud de demo enviada con éxito.', 'success', 5); 
$("#EmailDemo").val("");	
$('#get_demo').prop('disabled', false);
}else{
gsap.to(".supervision .preloader", {opacity:0, autoAlpha:0 ,duration: 1, ease: "elastic.out(1,1)"});
$("#EmailDemo").val("");
$('#get_demo').prop('disabled', false);
alertify.notify('Error al solicitar la demo.', 'error', 5); 
}
}
});
return false; 
});


}


const contacto = function(){

WindowHeight();
window.scrollTo(0, 0); 

$("body").css({'padding-bottom':  '0px'});

gsap.to(".graphs", {opacity:0.05, duration: 0.3, ease:"elastic.out(1,1)"});

alertify.set('notifier','position', 'bottom-center');

$(".head-logo img").attr("src", PathUrl+"/dist/img/logo-white.svg");
$(".hamburguer-wrapp").removeClass("black");

$('.title-contacto').flowtype({
fontRatio : 20,
minFont : 30,
});

$('.title-contacto2').flowtype({
fontRatio : 20,
minFont : 30,
});

$('.title-contacto3').flowtype({
fontRatio : 25,
minFont : 30,
});

$('.title-contacto4').flowtype({
fontRatio : 25,
minFont : 30,
});

$('.title-contacto5').flowtype({
fontRatio : 25,
minFont : 30,
});

$('.title-contacto6').flowtype({
fontRatio : 25,
minFont : 30,
});

$('.title-contacto7').flowtype({
fontRatio : 32,
minFont : 30,
});

$('.text-input').flowtype({
fontRatio : 35,
minFont : 14,
});

const title_contacto = document.querySelector('.title-contacto');
const results1 = Splitting({ target: title_contacto, by: 'chars' });
const title_contacto2 = document.querySelector('.title-contacto2');
const results2 = Splitting({ target: title_contacto2, by: 'chars' });
const title_contacto3 = document.querySelector('.title-contacto3');
const results3 = Splitting({ target: title_contacto3, by: 'chars' });
const title_contacto4 = document.querySelector('.title-contacto4');
const results4 = Splitting({ target: title_contacto4, by: 'chars' });
const title_contacto5 = document.querySelector('.title-contacto5');
const results5 = Splitting({ target: title_contacto5, by: 'chars' });

gsap.set(".side-dowm", {y: 100, opacity:0});
gsap.set(".title-contacto .char", {y: 200,});
gsap.set(".header", {y: -100, opacity:0});
gsap.set(".step", {y: 100, opacity:0});

var tl_cont0 = gsap.timeline({delay: 0.4});
tl_cont0.to(".title-contacto", {opacity:1, duration: 0.3, onComplete:function(){
    tl_cont0.to(".title-contacto .char", {y: 0, duration: 0.8, ease: "elastic.out(1,1)", stagger: 0.02, onComplete:function(){
    tl_cont0.to(".side-dowm", {y: 0, opacity:1 ,duration: 0.8, ease: "elastic.out(1,1)"});
    gsap.to(".header", {y: 0, opacity:1 ,duration: 0.8, ease: "elastic.out(1,1)"});
    gsap.to($(".step").first(), {scale: 1.5, opacity:1 ,duration: 0.8, ease: "elastic.out(1,1)"});
    gsap.to(".step", {y: 0, opacity:1 ,duration: 0.8, ease: "elastic.out(1,1)", stagger: 0.1,});
}});
}});

gsap.set(".side-dowm2", {y: 100, opacity:0});
gsap.set(".title-contacto2 .char", {y: 200,});
var tl_cont1 = gsap.timeline({paused:true});
tl_cont1.to(".title-contacto2", {opacity:1, duration: 0.3, onComplete:function(){
    tl_cont1.to(".title-contacto2 .char", {y: 0, duration: 0.8, ease: "elastic.out(1,1)", stagger: 0.02, onComplete:function(){
    tl_cont1.to(".side-dowm2", {y: 0, opacity:1 ,duration: 0.8, ease: "elastic.out(1,1)"});
}});
}});


gsap.set(".side-dowm3", {y: 100, opacity:0});
gsap.set(".title-contacto3 .char", {y: 200,});
var tl_cont2 = gsap.timeline({paused:true});
tl_cont2.to(".title-contacto3", {opacity:1, duration: 0.3, onComplete:function(){
    tl_cont2.to(".title-contacto3 .char", {y: 0, duration: 0.8, ease: "elastic.out(1,1)", stagger: 0.02, onComplete:function(){
    tl_cont2.to(".side-dowm3", {y: 0, opacity:1 ,duration: 0.8, ease: "elastic.out(1,1)"});
}});
}});

gsap.set(".side-dowm4", {y: 100, opacity:0});
gsap.set(".title-contacto4 .char", {y: 200,});
var tl_cont3 = gsap.timeline({paused:true});
tl_cont3.to(".title-contacto4", {opacity:1, duration: 0.3, onComplete:function(){
    tl_cont3.to(".title-contacto4 .char", {y: 0, duration: 0.8, ease: "elastic.out(1,1)", stagger: 0.02, onComplete:function(){
    tl_cont3.to(".side-dowm4", {y: 0, opacity:1 ,duration: 0.8, ease: "elastic.out(1,1)"});
}});
}});

gsap.set(".side-dowm5", {y: 100, opacity:0});
gsap.set(".title-contacto5 .char", {y: 200,});
var tl_cont4 = gsap.timeline({paused:true});
tl_cont4.to(".title-contacto5", {opacity:1, duration: 0.3, onComplete:function(){
    tl_cont4.to(".title-contacto5 .char", {y: 0, duration: 0.8, ease: "elastic.out(1,1)", stagger: 0.02, onComplete:function(){
    tl_cont4.to(".side-dowm5", {y: 0, opacity:1 ,duration: 0.8, ease: "elastic.out(1,1)"});
}});
}});

gsap.set(".side-dowm6", {y: 100, opacity:0});
var tl_cont5 = gsap.timeline({paused:true});
tl_cont5.to(".title-contacto6", {opacity:1, duration: 0.3, onComplete:function(){
    tl_cont5.to(".side-dowm6", {y: 0, opacity:1 ,duration: 0.8, ease: "elastic.out(1,1)"});
}});

gsap.set(".side-dowm7", {y: 100, opacity:0});
var tl_cont6 = gsap.timeline({paused:true});
tl_cont6.to(".title-contacto7", {opacity:1, duration: 0.3, onComplete:function(){
    tl_cont6.to(".side-dowm7", {y: 0, opacity:1 ,duration: 0.8, ease: "elastic.out(1,1)"});
}});


var SlideContacto = new Swiper(".contacto-slide", {
disableOnInteraction: true,
simulateTouch: false,
allowTouchMove:false,
autoHeight: true,
hashNavigation: {
watchState: true,
},
effect: 'fade',
fadeEffect: {
crossFade: true
}
});

$("#ContPhone").inputmask({ mask: "+56\\9\\ 9999-9999", greedy: false});

$("#ContEmail").inputmask({
mask: "*{1,20}[.*{1,20}][.*{1,20}][.*{1,20}]@*{1,20}[.*{2,6}][.*{1,2}]",
greedy: false,
onBeforePaste: function (pastedValue, opts) {
pastedValue = pastedValue.toLowerCase();
return pastedValue.replace("mailto:", "");
},
definitions: {
'*': {
validator: "[0-9A-Za-z!#$%&'*+/=?^_`{|}~\-]",
casing: "lower"
}
}
});

SlideContacto.on('slideChangeTransitionEnd', function (e) {

    let active_index = e.activeIndex;
    if(active_index == "0"){

        $(".step").removeClass("active");
        $(".step1").addClass("active");
        gsap.to(".bar-step", {width: "0%", duration: 0.5});
        gsap.to(".step1", {opacity: 1, scale:1.5, duration: 0.5});
        gsap.to(".step2", {opacity: 0.5, scale:1, duration: 0.5});
        gsap.to(".step3", {opacity: 0.5, scale:1, duration: 0.5});
        gsap.to(".step4", {opacity: 0.5, scale:1, duration: 0.5});
        gsap.to(".step5", {opacity: 0.5, scale:1, duration: 0.5});
        tl_cont0.timeScale(1).delay(0).play(0);
        tl_cont1.timeScale(6).delay(2).reverse(0);
        tl_cont2.timeScale(6).delay(2).reverse(0);
        tl_cont3.timeScale(6).delay(2).reverse(0);
        tl_cont4.timeScale(6).delay(2).reverse(0);
        tl_cont5.timeScale(6).delay(2).reverse(0);
        tl_cont6.timeScale(6).delay(2).reverse(0);

    }else if(active_index == "1"){

        $(".step").removeClass("active");
        $(".step2").addClass("active");
        gsap.to(".bar-step", {width: "25%", duration: 0.5});
        gsap.to(".step1", {opacity: 1, scale:1, duration: 0.5});
        gsap.to(".step2", {opacity: 1, scale:1.5, duration: 0.5});
        gsap.to(".step3", {opacity: 0.5, scale:1, duration: 0.5});
        gsap.to(".step4", {opacity: 0.5, scale:1, duration: 0.5});
        gsap.to(".step5", {opacity: 0.5, scale:1, duration: 0.5});
        $(".title-contacto2 .nombrecontacto .nombrecontacto2").html("");
        let  ContName = $("#ContName").val().split(" "),
        s = ContName[0];
        $(".title-contacto2 .nombrecontacto .nombrecontacto2").append(ContName[0]);   
        tl_cont0.timeScale(6).delay(2).reverse(0);
        tl_cont1.timeScale(1).delay(2).play(0);
        tl_cont2.timeScale(6).delay(2).reverse(0);
        tl_cont3.timeScale(6).delay(2).reverse(0);
        tl_cont4.timeScale(6).delay(2).reverse(0);
        tl_cont5.timeScale(6).delay(2).reverse(0);
        tl_cont6.timeScale(6).delay(2).reverse(0);

    }else if(active_index == "2"){

        $(".step").removeClass("active");
        $(".step3").addClass("active");
        gsap.to(".bar-step", {width: "50%", duration: 0.5});
        gsap.to(".step1", {opacity: 1, scale:1, duration: 0.5});
        gsap.to(".step2", {opacity: 1, scale:1, duration: 0.5});
        gsap.to(".step3", {opacity: 1, scale:1.5, duration: 0.5});
        gsap.to(".step4", {opacity: 0.5, scale:1, duration: 0.5});
        gsap.to(".step5", {opacity: 0.5, scale:1, duration: 0.5});
        tl_cont0.timeScale(6).delay(2).reverse(0);
        tl_cont1.timeScale(6).delay(2).reverse(0);
        tl_cont2.timeScale(1).delay(2).play(0);
        tl_cont3.timeScale(6).delay(2).reverse(0);
        tl_cont4.timeScale(6).delay(2).reverse(0);
        tl_cont5.timeScale(6).delay(2).reverse(0);
        tl_cont6.timeScale(6).delay(2).reverse(0);
    }

    else if(active_index == "3"){

        $(".step").removeClass("active");
        $(".step4").addClass("active");
        gsap.to(".bar-step", {width: "75%", duration: 0.5});
        gsap.to(".step1", {opacity: 1, scale:1, duration: 0.5});
        gsap.to(".step2", {opacity: 1, scale:1, duration: 0.5});
        gsap.to(".step3", {opacity: 1, scale:1, duration: 0.5});
        gsap.to(".step4", {opacity: 1, scale:1.5, duration: 0.5});
        gsap.to(".step5", {opacity: 0.5, scale:1, duration: 0.5});
        tl_cont0.timeScale(6).delay(2).reverse(0);
        tl_cont1.timeScale(6).delay(2).reverse(0);
        tl_cont2.timeScale(6).delay(2).reverse(0);
        tl_cont3.timeScale(1).delay(2).play(0);
        tl_cont4.timeScale(6).delay(2).reverse(0);
        tl_cont5.timeScale(6).delay(2).reverse(0);
        tl_cont6.timeScale(6).delay(2).reverse(0);
    }
    else if(active_index == "4"){

        $(".step").removeClass("active");
        $(".step5").addClass("active");
        gsap.to(".bar-step", {width: "100%", duration: 0.5});
        gsap.to(".step1", {opacity: 1, scale:1, duration: 0.5});
        gsap.to(".step2", {opacity: 1, scale:1, duration: 0.5});
        gsap.to(".step3", {opacity: 1, scale:1, duration: 1});
        gsap.to(".step4", {opacity: 1, scale:1, duration: 0.5});
        gsap.to(".step5", {opacity: 1, scale:1.5, duration: 0.5});
        tl_cont0.timeScale(6).delay(2).reverse(0);
        tl_cont1.timeScale(6).delay(2).reverse(0);
        tl_cont2.timeScale(6).delay(2).reverse(0);
        tl_cont3.timeScale(6).delay(2).reverse(0);
        tl_cont4.timeScale(1).delay(2).play(0);
        tl_cont5.timeScale(6).delay(2).reverse(0);
        tl_cont6.timeScale(6).delay(2).reverse(0);
    }
    else if(active_index == "5"){
        tl_cont0.timeScale(6).delay(2).reverse(0);
        tl_cont1.timeScale(6).delay(2).reverse(0);
        tl_cont2.timeScale(6).delay(2).reverse(0);
        tl_cont3.timeScale(6).delay(2).reverse(0);
        tl_cont4.timeScale(6).delay(2).reverse(0);
        tl_cont5.timeScale(1).delay(2).play(0);
        tl_cont6.timeScale(6).delay(2).reverse(0);
    }
    else if(active_index == "6"){
        tl_cont0.timeScale(6).delay(2).reverse(0);
        tl_cont1.timeScale(6).delay(2).reverse(0);
        tl_cont2.timeScale(6).delay(2).reverse(0);
        tl_cont3.timeScale(6).delay(2).reverse(0);
        tl_cont4.timeScale(6).delay(2).reverse(0);
        tl_cont5.timeScale(6).delay(2).reverse(0);
        tl_cont6.timeScale(1).delay(2).play(0);
    }

});

$(".button-prev").on("click",function(e){
e.preventDefault();
var slide = $(this).attr("data-slide");
SlideContacto.slideTo(slide,500);
});

$("#GoToPhone").on("click",function(e){
e.preventDefault();
var slide = $(this).attr("data-slide");
if($("#ContName").val().length < 5) { 
alertify.notify('Ingresa tu nombre completo.', 'error', 2); 
return false;  
}
SlideContacto.slideTo(slide,500);
});

$("#GoToEmail").on("click",function(e){
e.preventDefault();
var slide = $(this).attr("data-slide");
if($("#ContPhone").val().length < 4) {  
alertify.notify('Ingresa tu teléfono.', 'error', 2);  
return false;  
}
if($("#ContPhone").val().replace(/[^0-9]/g,"").length < 11) { 
alertify.notify('Ingresa un teléfono valido.', 'error', 2);  
return false;   
}
SlideContacto.slideTo(slide,500);
});

$("#GotoAsnt").on("click",function(e){
e.preventDefault();
var slide = $(this).attr("data-slide");
if($("#ContEmail").val().length < 1) {  
alertify.notify('Ingresa tu email.', 'error', 2);   
return false;  
}  
if($("#ContEmail").val().indexOf('@', 0) == -1 || $("#ContEmail").val().indexOf('.', 0) == -1) {  
alertify.notify('El email ingresado contiene errores.', 'error', 2);  
return false;  
}
SlideContacto.slideTo(slide,500);
});


$("#GotoMsj").on("click",function(e){
e.preventDefault();
var slide = $(this).attr("data-slide");
if($("#ContAsunto").val().length < 10) { 
alertify.notify('Ingresa un asunto (Mínimo 10 caracteres).', 'error', 2); 
return false;  
}
SlideContacto.slideTo(slide,500);
});

var emailsend = document.getElementById("email-send"),
animaion1 = bodymovin.loadAnimation({
wrapper: emailsend,
animType: 'canvas',
loop: true,
autoplay: false,
path: PathUrl+'dist/icons/email.json'
});

var contactook = document.getElementById("email-ok"),
animaion2 = bodymovin.loadAnimation({
wrapper: contactook,
animType: 'canvas',
loop: true,
autoplay: false,
path: PathUrl+'dist/icons/icon-okay.json'
});


$("#SendCont").on("click",function(e){
e.preventDefault();
$("#ContMsj").focus();
var slide = $(this).attr("data-slide");
if($("#ContMsj").val().length < 20) { 
alertify.notify('Escribe un mensaje (Mínimo 20 caracteres).', 'error', 2); 
return false;  
}
gsap.to(".steps", {y:200, opacity:0, autoAlpha:0, duration: 0.5, ease:"elastic.out(1,1)"});
animaion1.play();
SlideContacto.slideTo(slide,500);
var action = "Contact",
UserName = $("#ContName").val(),
UserEmail = $("#ContEmail").val(),	
UserPhone = $("#ContPhone").val(),	
UserAffair = $("#ContAsunto").val(),
UserMsj = $("#ContMsj").val(),		
DataUser = {action:action, UserName:UserName, UserEmail:UserEmail, UserPhone:UserPhone, UserAffair:UserAffair, UserMsj:UserMsj};
$.ajax({
data:  DataUser,
url:   SiteUrl+"/json",
type:  'post',
cache: false,
success:function(response) {	
if(response.message == "success"){
$("#ContName").val("");
$("#ContEmail").val("");	
$("#ContPhone").val("");	
$("#ContAsunto").val("");
$("#ContMsj").val("");
animaion2.play();
SlideContacto.slideNext(500);
setTimeout(function() { 
    window.location.href = SiteUrl;
}, 12000);
}else{

}
}
});
return false; 
});
}

const cotiza = function(){ 

WindowHeight();
window.scrollTo(0, 0); 

$(".hamburguer-wrapp").removeClass("black");
$(".head-logo img").attr("src", PathUrl+"/dist/img/logo-white.svg");
$("body").css({'padding-bottom':  '0px'});

var PageRange = document.querySelector('.page-range');
var init = new Powerange(PageRange, { hideRange: true, min: 2, max: 100, start: 5 });
PageRange.onchange = function() {
    document.getElementById('pagenum').innerHTML = PageRange.value;
};


var MonthRange = document.querySelector('.month-range');
var init_mont = new Powerange(MonthRange, { hideRange: true, min: 1, max: 48, start: 1});
MonthRange.onchange = function() {
    document.getElementById('monthnum').innerHTML = MonthRange.value;
};

var LangRange = document.querySelector('.lang-range');
var init_lang = new Powerange(LangRange, { hideRange: true, min: 1, max: 10, start: 1});
LangRange.onchange = function() {
    document.getElementById('langnum').innerHTML = LangRange.value;
};

$(".item-checkbox").on("change",function(e){
e.preventDefault();
let section = $(this).attr("data-section");
if($($(this)).is(':checked')){
    $("."+section+"").slideDown();
}else{
    $("."+section+"").slideUp();
}
});

$('.title-cotiza').flowtype({
fontRatio : 10,
minFont : 40,
});

$('.serv-title').flowtype({
fontRatio : 40,
minFont : 12,
});


const title_cotiza = document.querySelector('.title-cotiza');
const results1 = Splitting({ target: title_cotiza, by: 'chars' });

gsap.set(".info-cotiza", {y: 100, opacity:0});
gsap.set(".select-cotiza", {y: 100, opacity:0});
gsap.set(".title-cotiza .char", {y: 200,});
gsap.set(".header", {y: -100, opacity:0});
gsap.set(".btns-servs", {y: 100, opacity:0});


var tl_cot = gsap.timeline({delay: 0.4});
tl_cot.to(".title-cotiza", {opacity:1, duration: 0.3, onComplete:function(){
tl_cot.to(".title-cotiza .char", {y: 0, duration: 0.8, ease: "elastic.out(1,1)", stagger: 0.02, onComplete:function(){
    gsap.to(".select-cotiza", {y: 0, opacity:1 ,duration: 0.8, ease: "elastic.out(1,1)"});
    gsap.to(".info-cotiza", {y: 0, opacity:1 ,duration: 0.8, ease: "elastic.out(1,1)"});
    gsap.to(".header", {y: 0, opacity:1 ,duration: 0.8, ease: "elastic.out(1,1)"});
    gsap.to(".btns-servs", {y: 0, opacity:1 ,duration: 0.8, ease: "elastic.out(1,1)"});
}});
}});

}

const myRoutes = [{
path: '/',
name: 'home'
}, {
path: '/contacto/',
name: 'contacto'
}, {
path: '/servicios/',
name: 'servicios'
}, {
path: '/somos/',
name: 'somos'
}, {
path: '/cotiza/',
name: 'cotiza'
}];

barba.use(barbaRouter, {
routes: myRoutes
});

barba.init({
sync: true,
transitions: [{
    name: 'opacity-transition',
    async beforeLeave(data) {
        let barba_url = data.next.url.path,
        barba_parts_url = barba_url.split("/"),
        title_page = "",
        last_part_url = barba_parts_url[barba_parts_url.length-2];
        if(last_part_url == ""){
            title_page = "inicio";
        }else{
            title_page = last_part_url;
        }
        $(".title-page").html(title_page);
    },
    async leave(data) {
        const done = this.async();
        transition.restart();
        setTimeout(function () {
            done();
        }, 1300);
    },
    async enter(data){ 
        transition.play();
    }
}],
views: [
    {
        namespace: 'home',
        beforeEnter(data) {
            gsap.to(".page-template-default", {opacity:1 ,duration: 1,onComplete:function(){
                gsap.to(".footer", {delay:0.5, opacity:1 ,duration: 1});
            }});
        },
        afterEnter() {
            Home();
        },
    },{
        namespace: 'contacto',
        beforeEnter(data) {   
            gsap.to(".page-template-default", {opacity:1 ,duration: 1,onComplete:function(){
                gsap.to(".footer", {delay:0.5, opacity:1 ,duration: 1});
            }});
        },
        afterEnter() {
            contacto();
        },
    },{
        namespace: 'servicios',
        beforeEnter(data) {   
            gsap.to(".page-template-default", {opacity:1 ,duration: 1,onComplete:function(){
                gsap.to(".footer", {delay:0.5, opacity:1 ,duration: 1});
            }});
        },
        afterEnter() {
            servicios();
        },
    },{
        namespace: 'somos',
        beforeEnter(data) {
            gsap.to(".page-template-default", {opacity:1 ,duration: 1,onComplete:function(){
                gsap.to(".footer", {delay:0.5, opacity:1 ,duration: 1});
            }});
            
        },
        afterEnter() {
            somos();
        },
    },{
        namespace: 'cotiza',
        beforeEnter(data) {
            gsap.to(".page-template-default", {opacity:1 ,duration: 1});
        },
        afterEnter() {
            cotiza();
        },
    }
]   

});

$(window).on('resize', function(){
WindowHeight();
});

$(window).on('load', function(){
WindowHeight();
});

});
}(this, jQuery));